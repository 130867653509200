import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useProductType() {

  const router = useRouter();

  const getProductTypes = async (page) => {
    return await ApiService.query("market_sun_product_types",page);
  };

  const getProductType = async (id) => {
    return await ApiService.get("market_sun_product_types/" + id);


  };

  const destroyProductType = async (id) => {
    await ApiService.delete("market_sun_product_types/" + id);
  };

  const stoerProductType = async (data) => {
    await ApiService.post("market_sun_product_types", data);
    await router.push({ name: "apps.travels.market_sun.product-type.index" });
  };

  const updateProductType = async (data) => {
    await ApiService.put("market_sun_product_types/" + data.id, data);
    await router.push({
      name: "apps.travels.market_sun.product-type.edit",
      params: { id: data.id },
    });
  };

  

  return {
    getProductTypes,
    getProductType,
    destroyProductType,
    stoerProductType,
    updateProductType,
  };
}
